<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <AnalyzedGenre
      :title="'Genres'"
      :description="'Analyzed Genre'"
      @pushGenreToRoute="pushGenreToRoute"
    />
    <SelectedGenreArtists :genre="genre" />
  </div>
</template>
<script>
import AnalyzedGenre from "../components/AnalyzedGenre";
import SelectedGenreArtists from "../components/SelectedGenreArtists";
import { useRoute } from "vue-router";
import router from "@/router";
export default {
  name: "PreFilledGenres",
  components: {
    AnalyzedGenre,
    SelectedGenreArtists,
  },
  data() {
    return {
      loading: false,
      genre: null,
      route: useRoute(),
    };
  },
  mounted() {
    if (this.route.query && this.route.query.genre) {
      this.getArtistsByGenre(this.route.query.genre);
    }
  },
  methods: {
    pushGenreToRoute(genre) {
      router.push(`/aiArtists?genre=${genre}`);
      this.genre = genre;
    },
    getArtistsByGenre(genre) {
      this.genre = genre;
    },
  },
};
</script>

<style lang="scss" scoped></style>
